import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { mainYellow2 } from "../../../constants";
import { ConversationDialog, JobAiChatMessage } from "@sumit-platforms/types";
import { convertMarkedTextToHtml, getStringDirection } from "../../../utils";
import DOMPurify from "dompurify";

interface MessageBubbleProps {
  message: ConversationDialog["question"] | JobAiChatMessage | null;
  isAiMessage?: boolean;
}

export const ChatMessage: React.FC<MessageBubbleProps> = ({
  message,
  isAiMessage,
}) => {
  const [response, setResponse] = useState("");

  const getMarkedHtml = useCallback(async (text: string) => {
    return await convertMarkedTextToHtml(text);
  }, []);

  useEffect(() => {
    const fetchMessage = async () => {
      if (!message || !("answer" in message)) return;
      const result = await getMarkedHtml(message.answer);
      const sanitizedHtml = DOMPurify.sanitize(result);
      setResponse(sanitizedHtml);
    };
    if (isAiMessage) {
      fetchMessage();
    }
  }, [message]);

  const formatUserText = useCallback((text: string) => {
    const formattedText = text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
    return formattedText;
  }, []);

  return (
    message && (
      <Box
        className={"ChatMessage"}
        p={1}
        sx={{
          backgroundColor: isAiMessage ? "transparent" : mainYellow2,
          borderRadius: 2,
          width: "fit-content",
          maxWidth: "95%",
          wordBreak: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {isAiMessage ? (
          <Typography
            dir={getStringDirection(
              (message as JobAiChatMessage)?.answer || ""
            )}
            dangerouslySetInnerHTML={{ __html: response }}
          />
        ) : (
          <Typography dir={getStringDirection(message?.question || "")}>
            {formatUserText(message?.question || "")}
          </Typography>
        )}
      </Box>
    )
  );
};
