import React from "react";
import { MenuCategory } from "@sumit-platforms/types";
import { MenuCategoryItem } from "./MenuCategoryItem";
import {
  Menu,
  Trigger,
  Portal,
  Content,
  Separator,
} from "@radix-ui/react-menubar";

export const MenuBarCategory = ({ category }: { category: MenuCategory }) => {
  return (
    <Menu>
      <Trigger className="MenubarTrigger">{category.name}</Trigger>
      <Portal>
        <Content
          className="MenubarContent"
          align="start"
          sideOffset={5}
          alignOffset={-3}
        >
          {category.items
            .filter((item) => !item.hide)
            .map((item) => (
              <React.Fragment key={item.id}>
                {item.topSeparator && (
                  <Separator className="MenubarSeparator" />
                )}
                <MenuCategoryItem item={item} />
                {item.bottomSeparator && (
                  <Separator className="MenubarSeparator" />
                )}
              </React.Fragment>
            ))}
        </Content>
      </Portal>
    </Menu>
  );
};
