import React, { useRef } from "react";
import { Box } from "@mui/material";
import { LoadingDots } from "../../../index";
import { ConversationHistory, JobAiChatMessage } from "@sumit-platforms/types";
import { ChatErrorIcon } from "./ChatErrorIcon";
import { ChatDialog } from "./ChatDialog";
import { scrollToItem } from "../../../utils";

interface ChatBoxProps {
  messages: ConversationHistory;
  isLoading: boolean;
  messagesLoadingState: Record<string, boolean>;
  showSpinner: boolean;
  questionResponseError?: { message?: string } | null;
  onFeedback: (
    idMessage: string,
    feedback: JobAiChatMessage["feedback"],
    idJobAiChat: number
  ) => void;
  onCopy: (text: string) => void;
  onRerun: (messageId: string) => void;
}

export const ChatBox: React.FC<ChatBoxProps> = ({
  messages,
  isLoading,
  messagesLoadingState,
  showSpinner,
  onFeedback,
  onCopy,
  onRerun,
  questionResponseError,
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const chatEndRef = useRef<HTMLDivElement>(null);

  const scroll = (itemRef: React.RefObject<HTMLDivElement>) => {
    scrollToItem({
      containerRef: chatContainerRef,
      itemRef,
    });
  };

  return (
    <Box
      className={"ChatBox"}
      ref={chatContainerRef}
      sx={{
        flex: 1,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: "0 8px",
      }}
    >
      {messages.map((dialog) => (
        <ChatDialog
          key={dialog.idMessage}
          dialog={dialog}
          onFeedback={onFeedback}
          onCopy={onCopy}
          onRerun={onRerun}
          loading={isLoading}
          messagesLoadingState={messagesLoadingState}
          scroll={scroll}
        />
      ))}
      {showSpinner && <LoadingDots />}
      {questionResponseError && (
        <ChatErrorIcon message={questionResponseError?.message} />
      )}
      <div className={"chatEndRef"} ref={chatEndRef} />
    </Box>
  );
};
