import { useCallback } from "react";
import { MenuItem, ShortcutAction } from "@sumit-platforms/types";
import {
  useDetectDevice,
  useKeyboardShortcuts,
} from "@sumit-platforms/ui-bazar/hooks";
import { prettifyKey } from "@sumit-platforms/ui-bazar/utils";
import { Checkbox } from "@sumit-platforms/ui-bazar";
import { CheckboxItem } from "@radix-ui/react-menubar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { mainYellow } from "@sumit-platforms/ui-bazar/constants";

const DefaultIconSlot = ({ item }: { item: MenuItem }) =>
  item.icon ? (
    <FontAwesomeIcon icon={item.icon} size={"1x"} />
  ) : (
    <span style={{ width: "15px" }} />
  );

export const MenuItemContent = ({ item }: { item: MenuItem }) => {
  const { userShortcuts } = useKeyboardShortcuts();
  const { isMacOS } = useDetectDevice();

  const getUserShortcut = useCallback(
    (shortcutAction: ShortcutAction) => {
      const shortcut = userShortcuts.find((sc) => sc.action === shortcutAction);
      if (!shortcut) return "";
      return prettifyKey(shortcut.keys, { isMacOS });
    },
    [isMacOS, userShortcuts]
  );

  return (
    <div className="MenuItemContent">
      <div className="IconSlot">
        {item.type === "toggle" && item.checked ? (
          <CheckboxItem
            key={item.id}
            checked={item.checked}
            disabled={item.disabled}
          >
            <Checkbox
              checked={item.checked}
              checkedIcon={
                <FontAwesomeIcon
                  icon={faCheck}
                  color={mainYellow}
                  className={"checkedIcon"}
                />
              }
              sx={{
                padding: 0,
                cursor: "inherit",
                "&:hover": { backgroundColor: "transparent" },
                "& .MuiTouchRipple-root": { display: "none" },
              }}
            />
          </CheckboxItem>
        ) : (
          <DefaultIconSlot item={item} />
        )}
      </div>

      <span>{item.name}</span>
      <div className="RightSlot">
        {/*TODO align string result from KeyboardShortcutItem component*/}
        {item.shortcutId ? getUserShortcut(item.shortcutId) : null}
        {item.endIcon ? (
          <FontAwesomeIcon icon={item.endIcon} size={"1x"} />
        ) : item.subItems ? (
          <FontAwesomeIcon icon={faChevronRight} size={"1x"} />
        ) : null}
      </div>
    </div>
  );
};
