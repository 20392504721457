import { useRef, useCallback, useLayoutEffect } from "react";
import { Box, Fade } from "@mui/material";
import { ConversationDialog, JobAiChatMessage } from "@sumit-platforms/types";
import { ChatMessage } from "./ChatMessage";
import { AiChatMessages } from "./AiChatMessages";

export const ChatDialog = ({
  dialog,
  onRerun,
  onFeedback,
  onCopy,
  loading,
  messagesLoadingState,
  scroll,
}: {
  dialog: ConversationDialog;
  onFeedback: (
    idMessage: string,
    feedback: JobAiChatMessage["feedback"],
    idJobAiChat: number
  ) => void;
  onCopy: (text: string) => void;
  onRerun: (messageId: string) => void;
  loading: boolean;
  messagesLoadingState: Record<string, boolean>;
  scroll: (itemRef: React.RefObject<HTMLDivElement>) => void;
}) => {
  const dialogStartRef = useRef<HTMLDivElement>(null);

  const scrollToStartDialog = useCallback(() => {
    scroll(dialogStartRef);
  }, [dialogStartRef]);

  useLayoutEffect(() => {
    setTimeout(scrollToStartDialog, 50);
  }, [dialog.answers?.length]);

  return (
    <Fade in>
      <Box className={"chatMessagesContainer"}>
        <div ref={dialogStartRef} />
        <Box dir={"rtl"}>
          <ChatMessage message={dialog.question} isAiMessage={false} />
        </Box>
        <Box dir={"ltr"}>
          <AiChatMessages
            aiMessages={dialog.answers || []}
            onFeedback={onFeedback}
            onCopy={onCopy}
            onRerun={onRerun}
            loading={loading}
            showSpinner={messagesLoadingState[dialog.idMessage]}
          />
        </Box>
      </Box>
    </Fade>
  );
};
