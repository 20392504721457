import { MenuItem } from "@sumit-platforms/types";
import { MenuItemContent } from "./MenuItemContent";
import {
  Sub,
  SubTrigger,
  Portal,
  SubContent,
  Item,
} from "@radix-ui/react-menubar";

export const MenuCategoryItem = ({ item }: { item: MenuItem }) => {
  if (item.subItems) {
    //TODO: Sub-menu has not been tested yet. Should work okay, but please check it before
    return (
      <Sub key={item.name}>
        <SubTrigger className="MenubarSubTrigger">
          <MenuItemContent item={item} />
        </SubTrigger>
        <Portal>
          <SubContent className="MenubarSubContent" alignOffset={-5}>
            {item.subItems?.map((subItem) => (
              <MenuCategoryItem item={subItem}></MenuCategoryItem>
            ))}
          </SubContent>
        </Portal>
      </Sub>
    );
  }

  return (
    <Item
      className="MenubarItem"
      key={item.name}
      disabled={item.disabled}
      onClick={item.action as any}
    >
      <MenuItemContent item={item} />
    </Item>
  );
};
