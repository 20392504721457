import { useTranslation } from "react-i18next";
import React, { FC, useState } from "react";
import { Box } from "@mui/system";
import { faFileAlt, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Attachment,
  attachmentsMimeTypes,
  AttachmentType,
  fileInputAccept,
} from "@sumit-platforms/types";
import { Button } from "../../core/Button/Button";
import { Textarea } from "../../core/Textarea/Textarea";

import "./AttachmentsModal.scss";

export interface AttachmentsModalProps {
  cancel: () => void;
  upload: (files: File) => Promise<Attachment | null | undefined>;
  update: (note: string) => Promise<void>;
  attachments: Attachment[];
  removeAttachment?: (idAttachment: number) => void;
  handleDownloadAttachment?: (attachment: Attachment) => Promise<void>;
  note?: string;
}

export const AttachmentsModal: FC<AttachmentsModalProps> = ({
  cancel,
  upload,
  update,
  attachments,
  note,
  removeAttachment,
  handleDownloadAttachment,
}) => {
  const { t } = useTranslation();
  const fileInput = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [currAttachments, setCurrAttachments] = useState<any[]>(attachments);
  const [notes, setNotes] = useState<string>(note ? note : "");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addAttachments = () => {
    fileInput.current.click();
  };

  const handleUploadAttachment = async () => {
    try {
      const files = Array.from(
        fileInput.current.files ? fileInput.current.files : []
      );

      if (files.length === 0) return;
      setIsLoading(true);
      const newAttachments = await Promise.all(
        files.map((file) => upload(file))
      );
      if (!newAttachments?.length) return;
      setCurrAttachments((prev) => [...prev, ...newAttachments]);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      throw new Error(err);
    }
  };

  const handleNotesUpdate = async () => {
    try {
      setIsLoading(true);
      await update(notes);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      throw new Error(err);
    } finally {
      cancel();
    }
  };

  const handleAttachmentRemove = async (idAttachment: number) => {
    try {
      setIsLoading(true);
      if (removeAttachment) {
        await removeAttachment(idAttachment);
      }
      const newAttachments = currAttachments.filter(
        (a) => a.idAttachment !== idAttachment
      );
      setCurrAttachments(newAttachments);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      throw new Error(err);
    }
  };

  const onDownloadAttachmentClick = async (attachment: Attachment) => {
    if (isLoading) return;
    if (handleDownloadAttachment) {
      setIsLoading(true);
      await handleDownloadAttachment(attachment);
      setIsLoading(false);
    }
  };

  return (
    <div className="AttachmentsModal">
      <div className="formBody">
        <div className="upperBody">{t("attachments_instructions")}</div>
        <div className="middelBody">
          <input
            className={"attachmentsInput"}
            type="file"
            ref={fileInput}
            onChange={handleUploadAttachment}
            multiple
            accept={fileInputAccept(attachmentsMimeTypes)}
          />
          <Button
            loading={isLoading}
            onClick={addAttachments}
            children={t("upload_files")}
            color="primary"
          />
          {currAttachments && currAttachments.length > 0 && (
            <div className="listAttachments">
              {currAttachments
                ?.filter((a: Attachment) => a?.type === AttachmentType.NOTE)
                .map((a) => (
                  <div key={a.url || a.name} className="attachment">
                    <div
                      onClick={() => onDownloadAttachmentClick(a)}
                      className="attachmentName"
                      style={{
                        cursor: handleDownloadAttachment ? "pointer" : "auto",
                      }}
                    >
                      <FontAwesomeIcon
                        className={"fileIcon"}
                        icon={faFileAlt}
                      />
                      {a.name}
                    </div>
                    <FontAwesomeIcon
                      className={"trashIcon"}
                      icon={faTrash}
                      onClick={() => handleAttachmentRemove(a.idAttachment)}
                    />
                  </div>
                ))}
            </div>
          )}
          <Box width={"100%"} pt={4}>
            <Textarea
              placeholder={t("write_your_notes_here")}
              value={notes}
              setValue={setNotes}
            />
          </Box>
        </div>
        <div className="lowerBody">
          <Button onClick={cancel} variant="outlined">
            {t("cancel")}
          </Button>
          <Button
            loading={isLoading}
            onClick={handleNotesUpdate}
            variant="contained"
          >
            {t("confirm")}
          </Button>
        </div>
      </div>
    </div>
  );
};
