const scrollToItem = ({
  containerRef,
  itemRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  itemRef: React.RefObject<HTMLDivElement>;
}) => {
  if (containerRef.current && itemRef.current) {
    const container = containerRef.current;
    const item = itemRef.current;

    const containerRect = container.getBoundingClientRect();
    const itemRect = item.getBoundingClientRect();

    const relativeTop = itemRect.top - containerRect.top;
    const scrollTop = container.scrollTop;

    container.scrollTo({
      top:
        scrollTop +
        relativeTop -
        containerRect.height / 2 +
        itemRect.height / 2,
      behavior: "smooth",
    });
  }
};

export { scrollToItem };
