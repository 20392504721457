import { useContext, useEffect, useMemo } from "react";
import { MenuContext } from "./useMenuBar.context";
import { MenuBar } from "@sumit-platforms/types";
import _ from "lodash";

const debounceWait = 100;
export const useMenuBar = (menuBar?: MenuBar) => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenuBar must be used within MenuProvider");
  }
  const debouncedSetMenuBar = useMemo(
    () =>
      _.debounce((newMenuBar: MenuBar) => {
        if (JSON.stringify(newMenuBar) !== JSON.stringify(context.menuBar)) {
          context.setMenuBar(newMenuBar);
        }
      }, debounceWait),
    [context]
  );

  useEffect(() => {
    if (_.isEmpty(menuBar)) return;

    debouncedSetMenuBar(menuBar);

    return () => {
      debouncedSetMenuBar.cancel();
    };
  }, [menuBar, debouncedSetMenuBar]);

  return context.menuBar;
};
