import _ from "lodash";
import axios from "axios";
import { addHours } from "date-fns";
import {
  ActiveUpload,
  ActiveUploadTableRow,
  Client,
  JobTranscriptionSource,
  JobTypeMeta,
  MulterFileInfo,
  Upload,
  UploadSettings,
  UploadStatus,
  UploadTableRow,
  MediaProvider,
} from "@sumit-platforms/types";
import * as tus from "tus-js-client";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { getMinDeliveryDate } from "../utils/formatters";
import { extractFileExtension } from "../utils/string-formatter";
import { getMainMedia } from "../utils/media";

const UploadService = ({ config }: { config: any }) => {
  const endpoint = `${config.server.host}/${config.server.uploads}`;
  const tusEndpoint = `${endpoint}/tus`;
  const DELIVERY_MIN_DATE = getMinDeliveryDate(config.delivery.minDeliveryDays);
  const AUTO_TRANSCRIPT_DELIVERY_MIN_DATE = addHours(
    new Date(),
    config.delivery.minAutoTranscriptDeliveryHours
  );

  const createUploadRow = (
    upload: Upload & { file?: File }
  ): UploadTableRow => {
    const delivery =
      upload.delivery &&
      new Date(upload.delivery) >=
        (upload.jobType?.autoTranscript
          ? AUTO_TRANSCRIPT_DELIVERY_MIN_DATE
          : DELIVERY_MIN_DATE)
        ? upload.delivery
        : upload.jobType?.autoTranscript
        ? AUTO_TRANSCRIPT_DELIVERY_MIN_DATE
        : null;
    return {
      id: upload.idUpload,
      name: upload.name,
      jobType: upload.jobType,
      language: { input: upload.inputLanguage, output: upload.outputLanguage },
      delivery,
      actions: null,
      submit: null,
      note: upload.note || "",
      tags: upload.tags,
      createdAt: upload.createdAt,
      attachments: upload.attachments || [],
      media: upload.media || [],
      duration:
        upload.media.length > 0 ? getMainMedia(upload.media).duration : 0,
      price: upload.price,
      status: upload.status,
      disabled: upload.status === UploadStatus.uploading,
      isSubmitable: isUploadSubmitable(upload),
      settings: upload.settings,
      file: upload.file,
      validationPreset: upload.validationPreset,
      srcFile: upload.srcFile,
      project: upload.project,
    };
  };

  const validateMinDeliveryDate = (date: Date, isAutoTranscript?: boolean) => {
    if (isAutoTranscript) {
      return true;
    }
    return new Date(date) >= DELIVERY_MIN_DATE;
  };

  const isUploadSubmitable = (upload: Upload): boolean => {
    return (
      upload.status === UploadStatus.success &&
      !_.isNil(upload.project) &&
      upload.delivery !== null &&
      validateMinDeliveryDate(
        upload.delivery,
        upload.jobType?.autoTranscript
      ) &&
      upload.jobType !== null &&
      upload.inputLanguage?.length > 0 &&
      (upload.jobType.transcriptionSource === JobTranscriptionSource.SOURCE_FILE
        ? !_.isEmpty(upload.srcFile)
        : true)
    );
  };

  const createActiveUploadRow = (
    upload: ActiveUpload
  ): ActiveUploadTableRow => {
    return {
      id: upload.idUpload,
      name: upload.name,
      status: upload.status,
    };
  };

  const getUploads = async (): Promise<Upload[]> => {
    try {
      const response = await axios.get<Upload[]>(endpoint);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getClientUploads = async (clientId: number): Promise<Upload[]> => {
    try {
      const clientUploads = await axios.get<Upload[]>(
        `${endpoint}/client/${clientId}`
      );
      return clientUploads.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getUploadByUploadTempId = async (
    uploadTempId: string
  ): Promise<Upload> => {
    try {
      const response = await axios.get<Upload>(
        `${endpoint}/getUploadByUploadTempId`,
        {
          params: {
            uploadTempId,
          },
        }
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const uploadFilesWithGoogleDrive = async ({
    fileId,
    token,
    idUser,
    idClient,
    uploadTempId,
    progress,
  }: {
    fileId: string;
    token: string;
    idUser: number;
    idClient: number;
    uploadTempId: string;
    progress: (progressEvent: any) => void;
  }): Promise<Upload> => {
    try {
      const response = await axios.post(
        endpoint + "/googleDrive",
        {
          fileId,
          token,
          idUser,
          idClient,
          uploadTempId,
        },
        {
          onDownloadProgress: progress,
        }
      );

      if (response.status !== 200) throw new Error(response.statusText);
      return getUploadByUploadTempId(uploadTempId);
    } catch (err) {
      throw new Error("");
    }
  };

  const uploadDone = async ({
    fileLocation,
    filename,
    filetype,
    size,
    duration,
    idClient,
    idUser,
    idJob,
    provider,
  }: {
    fileLocation: string;
    filetype: string;
    filename: string;
    size: number;
    duration?: number;
    idClient: number;
    idUser: number;
    idJob?: number;
    provider?: MediaProvider;
  }) => {
    try {
      const response = await axios.post<Upload>(endpoint + "/uploadDone", {
        fileLocation,
        filename,
        filetype,
        size,
        duration,
        idClient,
        idUser,
        idJob,
        provider,
      });

      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error("upload error - failed upload on upload done");
      throw new Error("");
    }
  };

  const attachAafToUpload = async (upload: Upload, attachedFile: File) => {
    const aafUploadSettings = await uploadSettingsFile(
      upload.idUpload,
      attachedFile
    );
    const uploadSettings = {
      aaf: aafUploadSettings,
    };
    const newUploadSettings = await updateUploadSettings(
      [upload.idUpload],
      uploadSettings
    );
    return newUploadSettings;
  };

  const attachFilesFromUpload = async (
    attachedFiles: File[],
    upload: Upload
  ) => {
    for (const attachedFile of attachedFiles) {
      const fileExtension = extractFileExtension(attachedFile.name) || "";
      switch (fileExtension?.toLowerCase()) {
        case "aaf": {
          const newSettings = await attachAafToUpload(upload, attachedFile);
          upload.settings = { ...upload.settings, ...newSettings };
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const uploadErrorHandler = async (
    error: Error | tus.DetailedError,
    rej: (reason?: any) => void
  ) => {
    rej(error);
  };
  const uploadProgressHandler = ({
    bytesUploaded,
    bytesTotal,
    uploadStartTime,
    progress,
  }: {
    bytesUploaded: number;
    bytesTotal: number;
    uploadStartTime: number;
    progress: (progressInfo: any) => void;
  }) => {
    const uploadSpeed = (bytesUploaded / (Date.now() - uploadStartTime)) * 1000;
    progress({
      total: bytesTotal,
      loaded: bytesUploaded,
      speed: uploadSpeed,
    });
  };

  const getFileLocationFromUploadPath = (
    uploadUrl: string,
    uploadId: string
  ) => {
    const [tusPath, uploadPath] = uploadUrl?.split("tus") || []; // break the string to tus endpoint and the rest will be the file upload path
    const uploadPathSplitted = uploadPath.split("/");
    uploadPathSplitted[uploadPathSplitted.length - 1] = uploadId; // replace tus id with out id generated
    const uploadPathLocation = uploadPathSplitted.slice(1).join("/");
    return uploadPathLocation;
  };

  const onSuccess = async ({
    beforeUploadAttachedFilesCallback,
    file,
    attachedFiles,
    uploadUrl,
    fileId,
    idUser,
    idClient,
    idJob,
    res,
    provider,
    duration,
  }: {
    file: File;
    res: (value: Upload | PromiseLike<Upload>) => void;
    uploadUrl: string;
    fileId: string;
    duration?: number;
    idClient: number;
    idUser: number;
    idJob?: number;
    attachedFiles?: File[];
    beforeUploadAttachedFilesCallback?: () => any;
    provider?: MediaProvider;
  }) => {
    const fileLocation = getFileLocationFromUploadPath(uploadUrl, fileId);
    const upload = await uploadDone({
      fileLocation,
      filetype: file.type,
      filename: file.name,
      size: file.size,
      duration,
      idClient,
      idUser,
      idJob,
      provider,
    });
    try {
      if (attachedFiles) {
        beforeUploadAttachedFilesCallback &&
          beforeUploadAttachedFilesCallback();
        await attachFilesFromUpload(attachedFiles, upload);
      }
    } catch (e) {
      console.error("failed to upload attached files", e);
    }
    res(upload);
  };

  const uploadFile = async ({
    file,
    attachedFiles,
    duration,
    idClient,
    idUser,
    idJob,
    progress,
    // userToken,
    beforeUploadAttachedFilesCallback,
    provider,
  }: {
    file: File;
    attachedFiles?: File[];
    duration?: number;
    idClient: number;
    idUser: number;
    idJob?: number;
    progress: (progressInfo: any) => void;
    // userToken: string;
    beforeUploadAttachedFilesCallback?: () => any;
    provider?: MediaProvider;
  }): Promise<Upload> => {
    try {
      const uploadStartTime = Date.now();
      const retryDelays = [0, 1000, 3000, 5000];
      return new Promise((res, rej) => {
        const uppy = new Uppy().use(Tus, {
          withCredentials: true,
          endpoint: tusEndpoint,
          retryDelays,
        });
        const fileId = uppy.addFile({ data: file });
        uppy.setFileMeta(fileId, { idUpload: fileId });
        uppy.on("upload-progress", (file, { bytesUploaded, bytesTotal }) => {
          uploadProgressHandler({
            progress,
            uploadStartTime,
            bytesUploaded,
            bytesTotal,
          });
        });
        uppy.on("upload-success", async (_, result) => {
          try {
            await onSuccess({
              uploadUrl: result.uploadURL as string,
              fileId,
              file,
              duration,
              idClient,
              idUser,
              idJob,
              attachedFiles,
              provider,
              beforeUploadAttachedFilesCallback,
              res,
            });
          } catch (error: any) {
            uploadErrorHandler(error, rej);
          }
        });
        uppy.on("error", (error) => uploadErrorHandler(error, rej));

        uppy.upload(); // triggers upload

        return uppy;
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const merge = async (uploadIds: number[]): Promise<Upload> => {
    try {
      const response = await axios.put<Upload>(`${endpoint}/merge`, {
        uploadIds,
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const unmerge = async (
    idMedia: number
  ): Promise<{ originUpload: Upload; upload: Upload }> => {
    try {
      const response = await axios.put<{
        originUpload: Upload;
        upload: Upload;
      }>(`${endpoint}/unmerge`, {
        idMedia,
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateUploadsJobType = async (
    ids: number[],
    jobType: JobTypeMeta,
    onUploadProgress?: (data: any) => void
  ): Promise<Upload[]> => {
    try {
      const response = await axios.put<Upload[]>(
        endpoint + "/updateUploadsJobType",
        { jobType, ids }
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateMany = async (
    ids: number[],
    upload: Partial<Upload>,
    onUploadProgress?: (data: any) => void
  ): Promise<Upload[]> => {
    try {
      const response = await axios.put<Upload[]>(endpoint, { upload, ids });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const remove = async (ids: number[]): Promise<void> => {
    try {
      await axios.delete(endpoint, {
        params: {
          ids,
        },
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const setTags = async (
    idUploads: number[],
    idTags: number[]
  ): Promise<void> => {
    try {
      await axios.put(endpoint + "/setTags", {
        idUploads,
        idTags,
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const removeAttachmentFromUpload = async (
    idUpload: number,
    idAttachment: number
  ): Promise<void> => {
    try {
      await axios.delete(endpoint + "/removeAttachment", {
        params: {
          idUpload,
          idAttachment,
        },
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateUploadSettings = async (
    uploadIds: (number | string)[],
    uploadSettings: UploadSettings
  ): Promise<UploadSettings> => {
    try {
      const _uploadIds = uploadIds.map((id) => Number(id));
      const response = await axios.put(endpoint + "/updateUploadSettings", {
        uploadIds: _uploadIds,
        uploadSettings,
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const uploadSettingsFile = async (
    idUpload: number | string,
    file: File
  ): Promise<MulterFileInfo> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        endpoint + `/${idUpload}/uploadSettingsFile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) throw new Error(response.statusText);

      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  const uploadSourceFile = async (
    idUpload: number | string,
    file: File
  ): Promise<MulterFileInfo> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        endpoint + `/${idUpload}/uploadSourceFile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) throw new Error(response.statusText);

      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  const removeSourceFile = async (idUpload: number | string): Promise<void> => {
    try {
      const response = await axios.delete(endpoint + `/${idUpload}/sourceFile`);

      if (response.status !== 200) throw new Error(response.statusText);

      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getValidationsByClientAndJobTypes = (
    selectedUploads: Upload[],
    selectedClient: Client | null,
    row?: UploadTableRow
  ) => {
    const sameJobType = row
      ? selectedUploads?.every(
          (s, i, arr) => s.jobType?.typeName === arr[i].jobType?.typeName
        )
      : true;

    if (!sameJobType) return [];
    const _row =
      row || (!_.isEmpty(selectedUploads) ? selectedUploads[0] : undefined);
    const validations =
      selectedClient?.validations
        ?.filter((v) => _.isEqual(v.jobType, _row?.jobType?.typeName))
        .map((v) => ({
          label: v?.settings?.general_lang?.presetName,
          value: v?.idValidationPreset,
        })) || [];

    return validations;
  };

  return {
    createUploadRow,
    createActiveUploadRow,
    getUploads,
    getClientUploads,
    uploadFile,
    updateMany,
    remove,
    merge,
    unmerge,
    removeAttachmentFromUpload,
    setTags,
    updateUploadsJobType,
    uploadFilesWithGoogleDrive,
    updateUploadSettings,
    uploadSettingsFile,
    DELIVERY_MIN_DATE,
    AUTO_TRANSCRIPT_DELIVERY_MIN_DATE,
    getValidationsByClientAndJobTypes,
    uploadSourceFile,
    removeSourceFile,
  };
};

export default UploadService;
