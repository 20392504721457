import React from "react";
import { MenuBar } from "@sumit-platforms/types";
import Box from "@mui/material/Box";
import {
  Button,
  IconButton,
  primaryColor,
  whiteColor,
} from "@sumit-platforms/ui-bazar";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import "./MenuBar.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MenuBarFeaturedButtons = ({
  buttons,
}: {
  buttons: MenuBar["featuredButtons"];
}) => {
  const { t } = useTranslation();
  return (
    <Box className={"MenubarFeaturedButtons"}>
      {buttons
        ?.filter((btn) => !btn.hide)
        .map((button) => (
          <React.Fragment key={`${button.id}-${button.fullWidth?.toString()}`}>
            {button.fullWidth ? (
              <Button
                className="MenubarFeaturedButton"
                endIcon={
                  button.endIcon && <FontAwesomeIcon icon={button.endIcon} />
                }
                onClick={() => button.action()}
                disabled={button.disabled}
                sx={
                  button.checked
                    ? {
                        backgroundColor: whiteColor,
                        color: primaryColor,
                      }
                    : {}
                }
              >
                {t(button.name)}
              </Button>
            ) : (
              <IconButton
                className={"MenubarExclusiveIconButton"}
                icon={(button.icon || button.endIcon) as IconProp}
                onClick={button.action}
                selected={button.checked}
                tooltipText={button.name}
              />
            )}
          </React.Fragment>
        ))}
    </Box>
  );
};
