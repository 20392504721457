import { useMemo } from "react";
import _ from "lodash";
import { IconButton as MuiIconButton } from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton/IconButton";
import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { Tooltip } from "../Tooltip/Tooltip";

import "./IconButton.scss";

interface InternalProps {
  icon: IconProp;
  onClick: Function;
  selected?: boolean;
  size?: "small" | "medium" | "large";
  tooltipText?: string;
}

export const IconButton = ({
  onClick,
  icon,
  selected,
  size,
  tooltipText,
  ...props
}: Omit<IconButtonProps, "size" | "onClick"> & InternalProps) => {
  const _size = useMemo(() => {
    const sizes = {
      small: "16px",
      medium: "20px",
      large: "24px",
    };

    return size ? sizes[size] : sizes.small;
  }, [size]);

  return (
    <Tooltip title={tooltipText || ""}>
      <span>
        <MuiIconButton
          className={classNames("BazarIconButton", {
            selected,
          })}
          onClick={onClick as any}
          sx={{ borderRadius: "6px", fontSize: _size }}
        >
          <FontAwesomeIcon
            icon={icon}
            className={classNames("IconButtonIcon", {
              disabled: props.disabled,
            })}
          />
        </MuiIconButton>
      </span>
    </Tooltip>
  );
};
