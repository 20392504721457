import React from "react";
import { MenuBar } from "@sumit-platforms/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import { IconButton, Tooltip } from "@sumit-platforms/ui-bazar";

import "./MenuBar.scss";

export const MenuBarBadges = ({
  badges = [],
}: {
  badges: MenuBar["badges"];
}) => {
  if (!badges.length) return null;

  return (
    <Box className="MenubarBadges">
      {badges
        .filter((b) => !b.hide)
        .map((badge) => {
          return (
            <React.Fragment key={badge.id}>
              <Tooltip title={badge.tooltip || ""}>
                <div key={badge.id} className={"MenubarBadge"}>
                  {badge.onClick ? (
                    <IconButton
                      icon={badge.icon}
                      onClick={badge.onClick}
                      size={"medium"}
                      selected={badge.checked}
                    />
                  ) : (
                    <FontAwesomeIcon icon={badge.icon} color={badge.color} />
                  )}
                </div>
              </Tooltip>
            </React.Fragment>
          );
        })}
    </Box>
  );
};
